import React from 'react';
import './App.css';
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {deDE} from '@material-ui/core/locale';
import Navigation from './components/Navigation';

import {HashRouter, Route} from "react-router-dom";
import ResultsFemale from './components/ResultsFemale';
import ResultsMale from './components/ResultsMale';
import {grey} from '@material-ui/core/colors';
import ResultsMaleStage1 from './components/ResultsMaleStage1';
import ResultsMaleStage2 from './components/ResultsMaleStage2';
import ResultsMaleStage3 from './components/ResultsMaleStage3';
import ResultsMaleStage4 from './components/ResultsMaleStage4';
import ResultsFemaleStage1 from './components/ResultsFemaleStage1';
import ResultsFemaleStage2 from './components/ResultsFemaleStage2';
import ResultsFemaleStage3 from './components/ResultsFemaleStage3';
import ResultsFemaleStage4 from './components/ResultsFemaleStage4';
import ResultsMaleStage5 from './components/ResultsMaleStage5';
import ResultsFemaleStage5 from './components/ResultsFemaleStage5';
import ResultsMaleStage6 from './components/ResultsMaleStage6';
import ResultsFemaleStage6 from './components/ResultsFemaleStage6';
import ResultsMaleStage7 from './components/ResultsMaleStage7';
import ResultsFemaleStage7 from './components/ResultsFemaleStage7';
import ResultsMaleStage8 from './components/ResultsMaleStage8';
import ResultsFemaleStage8 from './components/ResultsFemaleStage8';

const theme = createMuiTheme({
  palette: {
    primary: {main: '#F4F4F4'},
    secondary: grey
  }
}, deDE);

function App() {

  return (
      <ThemeProvider theme={theme}>
        <HashRouter basename='/'>
          <Navigation/>
          <Route exact path="/" component={ResultsMale}/>
          <Route path="/male" component={ResultsMale}/>
          <Route path="/female" component={ResultsFemale}/>
          <Route path="/maleStage1" component={ResultsMaleStage1}/>
          <Route path="/maleStage2" component={ResultsMaleStage2}/>
          <Route path="/maleStage3" component={ResultsMaleStage3}/>
          <Route path="/maleStage4" component={ResultsMaleStage4}/>
          <Route path="/maleStage5" component={ResultsMaleStage5}/>
          <Route path="/maleStage6" component={ResultsMaleStage6}/>
          <Route path="/maleStage7" component={ResultsMaleStage7}/>
          <Route path="/maleStage8" component={ResultsMaleStage8}/>
          <Route path="/femaleStage1" component={ResultsFemaleStage1}/>
          <Route path="/femaleStage2" component={ResultsFemaleStage2}/>
          <Route path="/femaleStage3" component={ResultsFemaleStage3}/>
          <Route path="/femaleStage4" component={ResultsFemaleStage4}/>
          <Route path="/femaleStage5" component={ResultsFemaleStage5}/>
          <Route path="/femaleStage6" component={ResultsFemaleStage6}/>
          <Route path="/femaleStage7" component={ResultsFemaleStage7}/>
          <Route path="/femaleStage8" component={ResultsFemaleStage8}/>
        </HashRouter>
      </ThemeProvider>
  );
}

export default App;
