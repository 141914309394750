import React, {useEffect} from 'react';
import {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';

export default ({spreadsheetUri}) => {

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    axios.get(spreadsheetUri)
        .then(response => setRows(response.data.values));
  }, [spreadsheetUri]);

  return (
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Platz</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Pz.</TableCell>
                <TableCell>Kürzel</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(entry => (
                      <TableRow key={entry[0] + '-' + entry[1]}>
                        <TableCell>{entry[0]}</TableCell>
                        <TableCell>{entry[1]}</TableCell>
                        <TableCell>{entry[2]}</TableCell>
                        <TableCell>{entry[6]}</TableCell>
                      </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
  );

}
