import React from 'react';
import fetchData from './fetchResults';
import TableOfStageResults from './TableOfStageResults';
import {GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_6_URI} from '../constants';

export default () => {

  const config = {
    spreadsheetUri: GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_6_URI,
    columnOneIndex: 18,
    columnTwoIndex: 1,
    columnThreeIndex: 19,
    columnFourIndex: 6,
    columnFiveIndex: 4
  };

  return (
      <TableOfStageResults data={fetchData(config)}></TableOfStageResults>
  );
}
