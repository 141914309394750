const GOOGLE_SPREADSHEET_API_KEY = 'AIzaSyDwgFRV-_367ht8VIi1fxRwprlCVGAhAWY';
const GOOGLE_SPREADSHEET_API_BASE_URI = 'https://sheets.googleapis.com/v4/spreadsheets';
const GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID = '1wxzQ6O9wIK9_vtT0WeE_7yK-x9MAs-tDfmizFNIWne8';
const GOOGLE_SPREADSHEET_RESULTS_MALE_RANGE = 'Ergebnistabelle Männer!B5:H';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_RANGE = 'Ergebnistabelle Frauen!B5:H';

const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_1_RANGE = 'Ergebnistabelle Männer!B5:K';
const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_2_RANGE = 'Ergebnistabelle Männer!B5:M';
const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_3_RANGE = 'Ergebnistabelle Männer!B5:O';
const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_4_RANGE = 'Ergebnistabelle Männer!B5:Q';
const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_5_RANGE = 'Ergebnistabelle Männer!B5:S';
const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_6_RANGE = 'Ergebnistabelle Männer!B5:U';
const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_7_RANGE = 'Ergebnistabelle Männer!B5:W';
const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_8_RANGE = 'Ergebnistabelle Männer!B5:Y';

const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_1_RANGE = 'Ergebnistabelle Frauen!B5:K';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_2_RANGE = 'Ergebnistabelle Frauen!B5:M';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_3_RANGE = 'Ergebnistabelle Frauen!B5:O';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_4_RANGE = 'Ergebnistabelle Frauen!B5:Q';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_5_RANGE = 'Ergebnistabelle Frauen!B5:S';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_6_RANGE = 'Ergebnistabelle Frauen!B5:U';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_7_RANGE = 'Ergebnistabelle Frauen!B5:W';
const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_8_RANGE = 'Ergebnistabelle Frauen!B5:Y';

export const GOOGLE_SPREADSHEET_RESULTS_MALE_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;

export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_1_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_1_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_2_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_2_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_3_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_3_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_4_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_4_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_5_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_5_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_6_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_6_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_7_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_7_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_8_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_8_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_1_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_1_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_2_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_2_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_3_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_3_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_4_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_4_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_5_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_5_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_6_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_6_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_7_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_7_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
export const GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_8_URI = `${GOOGLE_SPREADSHEET_API_BASE_URI}/${GOOGLE_SPREADSHEET_RESULTS_SPREADSHEET_ID}/values/${GOOGLE_SPREADSHEET_RESULTS_FEMALE_STAGE_8_RANGE}?key=${GOOGLE_SPREADSHEET_API_KEY}`;
