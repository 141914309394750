import axios from 'axios';

function fetchData(config) {
  return axios.get(config.spreadsheetUri)
      .then(response => mapResponse(config, response));
}

function mapResponse(config, response) {
  return new Promise((resolve, reject) => {
    resolve(mapEntries(config, response.data.values));
  });
}

function mapEntries(config, values) {
  return values.map(entry => (
      [
        entry[config.columnOneIndex],
        entry[config.columnTwoIndex],
        entry[config.columnThreeIndex],
        entry[config.columnFourIndex],
        entry[config.columnFiveIndex]
      ]
  ));
}

export default fetchData;
