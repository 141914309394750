import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import BarChartIcon from '@material-ui/icons/BarChart';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default () => {

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  }));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    console.log('click', event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue(false); // unselect male or female selection
    setAnchorEl(null);
  };

  return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Tabs value={value} onChange={handleChange} indicatorColor="secondary" centered>
              <Tab icon={<FormatListNumberedIcon/>} label="Männer" to='/male' component={Link} {...a11yProps(0)} />
              <Tab icon={<FormatListNumberedIcon/>} label="Frauen" to='/female' component={Link} {...a11yProps(1)} />
            </Tabs>
            <Typography variant="h6" className={classes.title}></Typography>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                        aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <Typography variant="h6" className={classes.title}>
                Etappen
              </Typography>
              <BarChartIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
          <MenuItem onClick={handleClose} to='/maleStage1' component={Link}>Männer Etappe 1</MenuItem>
          <MenuItem onClick={handleClose} to='/maleStage2' component={Link}>Männer Etappe 2</MenuItem>
          <MenuItem onClick={handleClose} to='/maleStage3' component={Link}>Männer Etappe 3</MenuItem>
          <MenuItem onClick={handleClose} to='/maleStage4' component={Link}>Männer Etappe 4</MenuItem>
          <MenuItem onClick={handleClose} to='/maleStage5' component={Link}>Männer Etappe 5</MenuItem>
          <MenuItem onClick={handleClose} to='/maleStage6' component={Link}>Männer Etappe 6</MenuItem>
          <MenuItem onClick={handleClose} to='/maleStage7' component={Link}>Männer Etappe 7</MenuItem>
          <MenuItem onClick={handleClose} to='/maleStage8' component={Link}>Männer Etappe 8</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage1' component={Link}>Frauen Etappe 1</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage2' component={Link}>Frauen Etappe 2</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage3' component={Link}>Frauen Etappe 3</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage4' component={Link}>Frauen Etappe 4</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage5' component={Link}>Frauen Etappe 5</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage6' component={Link}>Frauen Etappe 6</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage7' component={Link}>Frauen Etappe 7</MenuItem>
          <MenuItem onClick={handleClose} to='/femaleStage8' component={Link}>Frauen Etappe 8</MenuItem>
        </Menu>
      </div>
  );

}
