import {GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_1_URI} from '../constants';
import React from 'react';
import fetchData from './fetchResults';
import TableOfStageResults from './TableOfStageResults';

export default () => {

  const config = {
    spreadsheetUri: GOOGLE_SPREADSHEET_RESULTS_MALE_STAGE_1_URI,
    columnOneIndex: 8,
    columnTwoIndex: 1,
    columnThreeIndex: 9,
    columnFourIndex: 6,
    columnFiveIndex: 4
  };

  return (
      <TableOfStageResults data={fetchData(config)}></TableOfStageResults>
  );
}
